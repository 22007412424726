import moment from "moment";

const SettingsReportmonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const dateFormat = (date) => {
    if (!date) return "-";
    const validDate = moment(date, moment.ISO_8601, true);
    return validDate.isValid()
        ? validDate.format("MMM DD, YYYY LT")
        : "-";
};

const TimeOfDayValues = ["AM", "PM"];

const MonthsOptions = [
    {
        label: "January",
        value: "01",
    },
    {
        label: "February",
        value: "02",
    },
    {
        label: "March",
        value: "03",
    },
    {
        label: "April",
        value: "04",
    },
    {
        label: "May",
        value: "05",
    },
    {
        label: "June",
        value: "06",
    },
    {
        label: "July",
        value: "07",
    },
    {
        label: "August",
        value: "08",
    },
    {
        label: "September",
        value: "09",
    },
    {
        label: "October",
        value: "10",
    },
    {
        label: "November",
        value: "11",
    },
    {
        label: "December",
        value: "12",
    },
];

const formatToCommonReadableFormat = (date) => {
    return moment(date).format("MMM DD, YYYY LT");
};

const formatToCommonReadableFormatDateOnly = (date) =>
    moment(date).format("MMM DD, YYYY");

const formatToCommonFormat = (date) => {
    return moment(date).format("YYYY-MM-DD");
};

const formatToMonthAndYearOnly = (date) => moment(date).format("MMM, YYYY");

const getSuffixForDayOfMonth = (day) => {
    const dayType1 = Number(day) % 10,
        dayType2 = Number(day) % 100;

    if (dayType1 === 1 && dayType2 !== 11) {
        return `${day}st`;
    }

    if (dayType1 === 2 && dayType2 !== 12) {
        return `${day}nd`;
    }

    if (dayType1 === 3 && dayType2 !== 13) {
        return `${day}rd`;
    }

    return `${day}th`;
};

const convertTimeFrom12HTo24H = (time) =>
    moment(time || "", ["HH:mm A"])
        .format("HH:mm")
        .toString();

const convertTimeFrom24HTo12H = (time) =>
    moment(time || "", ["HH:mm"])
        .format("hh:mm A")
        .toString();

const getDiffBetween2DatesInMinutes = (date1 = "", date2 = "") =>
    moment(date1).diff(moment(date2), "minutes") || 0;

// * Jobs related
const getNextExecutionDate = ({
    daily = "",
    isEndOfMonth = false,
    dayOfMonth = null,
}) => {
    if (daily) {
        const executionTimeToDate = moment(daily, ["HH:mm A"]);
        const nextExecution =
            moment(executionTimeToDate, ["HH:mm A"]).diff(
                moment(),
                "minutes"
            ) <= 0
                ? executionTimeToDate.add(1, "d")
                : executionTimeToDate;

        return {
            nextDate: formatToCommonReadableFormat(nextExecution),
            executionMonth: null,
        };
    } else {
        if (isEndOfMonth) {
            if (moment().diff(moment().endOf("M")) === 0) {
                return {
                    nextDate: formatToCommonReadableFormat(
                        moment().add(1, "M").endOf("M").startOf("D")
                    ),
                    executionMonth: null,
                };
            } else {
                return {
                    nextDate: formatToCommonReadableFormat(
                        moment().endOf("M").startOf("D")
                    ),
                    executionMonth: null,
                };
            }
        } else {
            const dateOfMonth = moment().date(dayOfMonth);
            const nextMonth = moment().add(1, "M");
            if (dateOfMonth.startOf("D").diff(moment().startOf("D")) <= 0) {
                if (
                    dayOfMonth >= nextMonth.startOf("M").date() &&
                    dayOfMonth <= nextMonth.endOf("M").date()
                ) {
                    return {
                        nextDate: formatToCommonReadableFormat(
                            dateOfMonth.add(1, "M").startOf("D")
                        ),
                        executionMonth: dateOfMonth
                            .add(1, "M")
                            .startOf("D")
                            .format("MMMM"),
                    };
                } else {
                    return {
                        nextDate: null,
                        executionMonth: dateOfMonth
                            .add(1, "M")
                            .startOf("D")
                            .format("MMMM"),
                    };
                }
            } else {
                if (
                    dayOfMonth >= moment().startOf("M").date() &&
                    dayOfMonth <= moment().endOf("M").date()
                ) {
                    return {
                        nextDate: formatToCommonReadableFormat(
                            dateOfMonth.startOf("D")
                        ),
                        executionMonth: dateOfMonth.startOf("D").format("MMMM"),
                    };
                } else {
                    return {
                        nextDate: null,
                        executionMonth: dateOfMonth.startOf("D").format("MMMM"),
                    };
                }
            }
        }
    }
};

const startOfDay = (date) => {
    const dateToConvert = date ? moment(date) : moment();
    return dateToConvert.startOf("day").toDate();
};

const endOfDay = (date) => {
    const dateToConvert = date ? moment(date) : moment();
    return dateToConvert.endOf("day").toDate();
};

const startOfDayUtc = (date) => {
    const dateToConvert = date ? moment(date) : moment();
    return dateToConvert.utc().startOf("day").toDate();
};

const endOfDayUtc = (date) => {
    const dateToConvert = date ? moment(date) : moment();
    return dateToConvert.utc().endOf("day").toDate();
};
// * !Jobs related

export {
    SettingsReportmonths,
    TimeOfDayValues,
    MonthsOptions,
    formatToCommonReadableFormat,
    formatToCommonFormat,
    formatToCommonReadableFormatDateOnly,
    formatToMonthAndYearOnly,
    getSuffixForDayOfMonth,
    getNextExecutionDate,
    convertTimeFrom12HTo24H,
    convertTimeFrom24HTo12H,
    getDiffBetween2DatesInMinutes,
    startOfDay,
    endOfDay,
    startOfDayUtc,
    endOfDayUtc,
    dateFormat,
};
